import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { addMeta } from "../utils/meta"

class BlogIndex extends React.Component {
  constructor() {
    super()

    this.state = {
      startPage: 0,
    }
  }

  renderStore() {
    if (window.RBExternalPortfolio) {
      // adds content to div#rb-xzfcxvzx
      new window.RBExternalPortfolio(
        "www.redbubble.com",
        "porum",
        2,
        2
      ).renderIframe()
    } else {
      setTimeout(this.renderStore, 500)
    }
  }

  componentDidMount() {
    addMeta()
    this.renderStore()
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const avatar = data.avatar
    const [latest, ...posts] = data.allMarkdownRemark.edges

    const latestNode = latest.node

    return (
      <Layout location={this.props.location} title={siteTitle} avatar={avatar}>
        <SEO title="none" url="" />
        <Bio />
        {/* show latest cartoon */}
        <article>
          <Link style={{ boxShadow: `none` }} to={latestNode.fields.slug}>
            <div
              style={{ maxWidth: "590px" }}
              dangerouslySetInnerHTML={{ __html: latestNode.html }}
            />
          </Link>
        </article>
        <section className="article-links">
          {posts.slice(0, 9).map(({ node }, index) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug} className="article-link">
                <header>
                  <h3
                    style={{
                      marginTop: rhythm(0),
                      marginBottom: 0,
                      fontWeight: 600,
                      fontFamily: "Open Sans, Arial, sans-serif",
                    }}
                  >
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                </header>
              </article>
            )
          })}
        </section>
        <section>
          <h2
            style={{
              marginTop: rhythm(1),
              marginLeft: rhythm(0.5),
              marginBottom: 0,
              fontWeight: 600,
              fontFamily: "Indie Flower, sans-serif",
            }}
          >
            T-shirts
          </h2>
          <div className="rb-portfolio">
            <div id="rb-xzfcxvzx"></div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/pjo-icon.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
